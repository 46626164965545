<template>
  <b-row class="donutchartRow">
    <b-col>
      <p>{{$t('winelist.chart.ekTotal')}} ({{$t('general.netPrice')}})</p>
      <h2>
        {{purchasePriceTotal.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
      </h2>
      <p>{{$t('winelist.chart.vkTotal')}} ({{$t('general.grossPrice')}})</p>
      <h2>
        {{sellingPriceTotal.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
      </h2>
      <p>{{$t('winelist.chart.vkTotal')}} ({{$t('general.netPrice')}})</p>
      <h2>
        {{Number(getNetPrice(sellingPriceTotal)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
      </h2>
    </b-col>
    <b-col>
      <div>
        <p style="text-align: right">
          {{$t('general.ek_short')}} ({{$t('general.netPrice')}})
        </p>
      </div>
      <div>
        <DoughnutChart v-bind="doughnutChartProps" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import DataService from '@/services/StrapiService'
import { mapGetters } from 'vuex'
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { ref, computed, defineComponent } from "@vue/composition-api";
import { getVenueCurrency, getNetPrice } from '@/utils/functions'
import { useI18n } from '@/composables/useI18n'

//const qs = require('qs');

Chart.register(...registerables);
export default defineComponent({
  name: "Chart",
  components: {
    DoughnutChart
  },
  props: {
    wineChartData: {},
    currency: String,
    type: String,
    selectedDate: null
  },
  data() {
    return {}
  },
  created () {
  },
  setup(props) {
    const { t } = useI18n()
    const data = ref({
      venueWines: [],
      purchasePriceTotal: [],
      sellingPriceTotal: [],
    })
    const labels = ref([])
    const loading = ref(true)
    let responseChartData = {}
    const purchasePriceTotal = ref(0.0)
    const sellingPriceTotal = ref(0.0)
    console.log("sellingPriceTotal", typeof(sellingPriceTotal))
    function roundToCurrencyFormat(num) {
      return Math.round(num)
    }

    function loadWinesChart() {
      const venueWineIds = props.wineChartData.wines.map(venueWine => venueWine.id)
      DataService.getVenueWinesChart(venueWineIds).then((response) => {
            responseChartData = response['data'].data
            refreshChart()
            loading.value = false
      }).catch((err) => {
            // handle error
            loading.value = false
            console.log("Das ist der Error", err)
      })
    }
    function loadHistoryWinesChart() {
      const venueWineIds = props.wineChartData.wines.map(venueWine => venueWine.id)
      DataService.getVenueWinesHistoryChart(venueWineIds, props.selectedDate).then((response) => {
            responseChartData = response['data'].data
            refreshChart()
            loading.value = false
      }).catch((err) => {
            // handle error
            loading.value = false
            console.log(err)
      })
    }

    function refreshChart() {
      let chartTypeData = responseChartData.landChartData
      
      switch (props.wineChartData.filter) {
        case "land":
          chartTypeData = responseChartData.landChartData
          break;
        case "region":
          chartTypeData = responseChartData.regionChartData
          break;
        case "grapeVariety":
          chartTypeData = responseChartData.grapeVarietyChartData
          break;
        case "wineType":
          chartTypeData = responseChartData.wineTypeChartData
          break;
        default:
          chartTypeData = responseChartData.landChartData
      }
      
      labels.value = chartTypeData.data.map(field => {
        return {
        localeKey: field.localeKey,
        title: field.title
        }
      })
      data.value.venueWines = chartTypeData.data.map(field => { return field.venueWines });
      data.value.purchasePriceTotal = chartTypeData.data.map(field => { return field.purchasePriceTotal });
      data.value.sellingPriceTotal = chartTypeData.data.map(field => { return field.sellingPriceTotal });

      purchasePriceTotal.value = roundToCurrencyFormat(chartTypeData.purchasePriceTotal)
      sellingPriceTotal.value = roundToCurrencyFormat(chartTypeData.sellingPriceTotal)
    }

    if (props.selectedDate) {
      loadHistoryWinesChart();
    } else {
      loadWinesChart();
    }

    const chartData = computed(() => ({
      labels: labels.value,
      datasets: [
        {
          data: data.value.purchasePriceTotal,
          purchasePriceTotal: data.value.purchasePriceTotal,
          sellingPriceTotal: data.value.sellingPriceTotal,
          backgroundColor: [
            "#7edd37",
            "#d631be",
            "#ffc107",
            "#27d6ca",
            "#dc3545",
            "#ffa3a3",
            "#7faaff",
            "#a6ffbb",
            "#d375ff",
            "#ffffff",
          ],
        },
      ],
    }));
    const options = computed(() => ({
      borderWidth: 0,
      plugins: {
        tooltip: {
          enabled: true,
          bodyFont: {
            size: 20
          },
          callbacks: {
            label: function(context) {
              var label = context.label.title || ''
              if (props.type == 'land') {
                label = t('countries.' + context.label.localeKey)
              } else if (props.type == 'wineType') {
                label = t('wineTypes.' + context.label.localeKey)
              } else if (props.type == 'region') {
                label = context.label.localeKey ? t('regions.' + context.label.localeKey) : context.label.title
              } else if (props.type == 'grapeVariety') {
                label = context.label.localeKey ? t('general.otherGrapeVariety') : context.label.title
              } 
              label += ": " + context.parsed.toFixed(2) + " " + props.currency
              return label;
            }
          }
        },
        legend: {
          position: "right",
          align: "start",
          labels: {
            font: {
              size: 14
            },
            textAlign: "right",
            boxWidth: 24,
            boxHeight: 24,
            color: '#fff',
            padding: 12,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const {labels: {pointStyle}} = chart.legend.options;

                return data.labels.map((label, i) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);

                  const ek = roundToCurrencyFormat(data.datasets[0].purchasePriceTotal[i])
                  // const vk = "VK: " + roundToCurrencyFormat(data.datasets[0].sellingPriceTotal[i])

                  var labelText = label.title + " - " + ek + " " + props.currency
                  if (props.type == 'land') {
                    labelText = t('countries.' + label.localeKey) + " - " + ek.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + props.currency
                  } else if (props.type == 'wineType') {
                    labelText = t('wineTypes.' + label.localeKey) + " - " + ek.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + props.currency
                  } else if (props.type == 'region') {
                    labelText = (label.localeKey ? t('regions.' + label.localeKey) : label.title) + " - " + ek.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + props.currency
                  } else if (props.type == 'grapeVariety') {
                    labelText = (label.localeKey ? t('general.otherGrapeVariety') : label.title) + " - " + ek.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + props.currency
                  } 

                  return {
                    text: labelText,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle: pointStyle,
                    hidden: !chart.getDataVisibility(i),
                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        },
        title: {
          display: false,
        },
      },
    }));

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData,
      options,
    });

    return { 
      loading, 
      doughnutChartProps, 
      doughnutChartRef, 
      purchasePriceTotal, 
      sellingPriceTotal, 
      loadWinesChart, 
      loadHistoryWinesChart, 
      t 
    };
  },//EO Setup
  methods: {
    getNetPrice,
    reloadChart() {
      if (this.selectedDate) {
        this.loadHistoryWinesChart()
      } else {
        this.loadWinesChart()
      }
    }
  },
  computed: {
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    ...mapGetters(['getVenue'])
  },
  watch: {
    wineChartData() {
      this.reloadChart()
    },
  }
});
</script>