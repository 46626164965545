<template>
  <b-dropdown-item-button class="export" @click="exportCSV()">{{ $t('general.buttons.exportCSV')}}</b-dropdown-item-button>
</template>

<script>
import { createCSVArrayFromVenueWineList, writeArrayToCSVDownload} from '@/utils/exportArticles'
import DateTimeService from '@/services/DateTimeService'

export default {
	name: 'ExportCSVButton',
	props: {
		venueWines: Array,
		venueTitle: String,
  },
	data() {
		return {
			exportRunning: false,
		}
	},
  methods: {
    exportCSV() {
			if (this.exportRunning) {
				return
			}
			this.exportRunning = true

			var csvArray = createCSVArrayFromVenueWineList(this.venueWines)
      var fileName = this.venueTitle + '_' + DateTimeService.dateToFileString(new Date())
      writeArrayToCSVDownload(csvArray, fileName, this.$papa )
      
			this.exportRunning = false
    },
  },
};
</script>
<style scoped>

</style>